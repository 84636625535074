import { AuthLayout } from '@app/components/layout/AuthLayout'
import { Form } from '@app/components/Form'
import { PasswordInput } from '@app/components/inputs/PasswordInput'
import { TextInput } from '@app/components/inputs/TextInput'
import { nextAuthOptions } from '@app/lib/server/nextAuthOptions'
import { Alert, Button, Divider, createStyles, Group, Box, Text } from '@mantine/core'
import { useForm, zodResolver } from '@mantine/form'
import { IconLogin, IconSend } from '@tabler/icons-react'
import { type GetServerSideProps, type NextPage } from 'next'
import Link from 'next/link'
import { type Session } from 'next-auth'
import { getServerSession } from 'next-auth/next'
import { signIn } from 'next-auth/react'
import { useRouter } from 'next/router'
import { useCallback, useMemo, useState } from 'react'
import { z } from 'zod'

type SignInErrorTypes =
  | 'Signin'
  | 'OAuthSignin'
  | 'OAuthCallback'
  | 'OAuthCreateAccount'
  | 'EmailCreateAccount'
  | 'Callback'
  | 'OAuthAccountNotLinked'
  | 'EmailSignin'
  | 'CredentialsSignin'
  | 'SessionRequired'
  | 'default'

interface LoginFormData {
  email: string
  password: string
}

interface LoginPageQueryParams {
  callbackUrl?: string
  error?: SignInErrorTypes
}

const useStyles = createStyles((theme) => ({
  wrapper: {
    width: '50%'
  },
  form: {
    width: '100%'
  },
  link: {
    color: theme.colors.brandSecondary[0],
    textDecoration: 'none',
    fontSize: '0.875rem'
  },
  button: {
    backgroundColor: theme.colors.brandPrimary[0],
    '&:hover': {
      backgroundColor: theme.colors.brandPrimary[0]
    }
  }
}))

const LoginPage: NextPage = () => {
  const router = useRouter()
  const { classes } = useStyles()

  const errors = useMemo<Record<SignInErrorTypes, string>>(() => {
    const basicError = 'Try logging in with a different account'

    return {
      Signin: basicError,
      OAuthSignin: basicError,
      OAuthCallback: basicError,
      OAuthCreateAccount: basicError,
      EmailCreateAccount: basicError,
      Callback: basicError,
      OAuthAccountNotLinked: 'To confirm your identity, log in with the same account you used originally',
      EmailSignin: 'The email could not be sent',
      CredentialsSignin: 'Incorrect email or password',
      SessionRequired: 'Please log in to access this page',
      default: 'Unable to login'
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.locale])
  const { callbackUrl = '/', error: errorCode }: LoginPageQueryParams = router.query
  const [error, setError] = useState(() => errorCode && errors[errorCode])
  const [isLoggingIn, setIsLoggingIn] = useState(false)
  // set form initial values
  const form = useForm<LoginFormData>({
    initialValues: {
      email: '',
      password: ''
    },
    validate: zodResolver(
      z.object({
        email: z.string().email({
          message: 'Enter a valid email'
        }),
        password: z.string()
      })
    ),
    validateInputOnChange: false
  })

  return (
    <AuthLayout title='Login'>
      <Group className={classes.wrapper}>
        <Form
          className={classes.form}
          buttons={
            <Button
              className={classes.button}
              disabled={isLoggingIn || !form.isValid()}
              fullWidth
              leftIcon={<IconLogin size={18} />}
              type='submit'
            >
              Login
            </Button>
          }
          isSaving={isLoggingIn}
          onSubmit={form.onSubmit(
            useCallback(
              async (values) => {
                setIsLoggingIn(true)

                const {
                  error: nextError,
                  ok,
                  url
                } = await signIn('credentials', {
                  ...values,
                  callbackUrl,
                  redirect: false
                })

                if (!ok) {
                  setError(nextError && (errors[nextError] ?? errors.default))
                  setIsLoggingIn(false)
                } else {
                  await router.push(url)
                }
              },
              [callbackUrl, errors, router]
            )
          )}
        >
          <TextInput
            autoComplete='email'
            disabled={isLoggingIn}
            error={form.errors.email}
            icon={null}
            onChange={useCallback(
              (event) => {
                setError(null)
                form.setFieldValue('email', event.currentTarget.value)
              },
              [form]
            )}
            label='Email Address'
            placeholder='Input your registered email'
            required
            type='email'
            value={form.values.email}
            mb={10}
          />
          <PasswordInput
            autoComplete='current-password'
            disabled={isLoggingIn}
            error={form.errors.password}
            icon={null}
            onChange={useCallback(
              (event) => {
                setError(null)
                form.setFieldValue('password', event.currentTarget.value)
              },
              [form]
            )}
            label='Password'
            placeholder='Input your password'
            required
            requirements={null}
            value={form.values.password}
            mb={10}
          />
          {error && (
            <Alert
              color='red'
              mb='lg'
              radius='sm'
              variant='filled'
            >
              {error}
            </Alert>
          )}
        </Form>
        <Group
          w='100%'
          position='center'
          spacing='xs'
        >
          <Text
            size='sm'
            color='dimmed'
          >
            Don’t you have an account?
          </Text>
          <Link
            href='/auth/signup'
            className={classes.link}
          >
            Sign Up
          </Link>
        </Group>
        <Box
          w='100%'
          my='md'
        >
          <Divider
            label='or'
            labelPosition='center'
            size='xs'
          />
        </Box>

        <Button
          className={classes.button}
          component={Link}
          disabled={isLoggingIn}
          href={`/auth/request-link?callbackUrl=${callbackUrl}`}
          fullWidth
          leftIcon={<IconSend size={18} />}
        >
          Login with Secure Link
        </Button>
      </Group>
    </AuthLayout>
  )
}

export const getServerSideProps: GetServerSideProps<{ session: Session }, { callbackUrl?: string }> = async ({
  req,
  res,
  params
}) => {
  const session = await getServerSession(req, res, nextAuthOptions)

  if (session?.user?.id) {
    return {
      redirect: {
        destination: (params?.callbackUrl as string) || req.headers.referer || '/',
        permanent: false
      }
    }
  }

  return {
    props: {
      session
    }
  }
}

// noinspection JSUnusedGlobalSymbols
export default LoginPage
