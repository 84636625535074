import { Button, Container, Paper, Title, Group, Image, Box, createStyles, Text } from '@mantine/core'
import { type FC, ReactNode } from 'react'
import Link from 'next/link'

const useStyles = createStyles((theme) => ({
  container: {
    display: 'flex',
    height: '100vh',
    width: '100vw',
    maxWidth: '100%'
  },
  leftPanel: {
    flex: 1,
    minWidth: '50%',
    minHeight: '100vh',
    backgroundImage: 'url(/login-signup.png)',
    backgroundSize: '100% 100%',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px'
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    color: 'white',
    padding: '20px',
    borderRadius: '8px',
    textAlign: 'center',
    maxWidth: '80%',
    wordWrap: 'break-word'
  },
  rightPanel: {
    flex: 1,
    minWidth: '50%',
    minHeight: '100vh',
    overflowY: 'scroll',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  form: {
    width: '70%'
  },
  link: {
    color: '#F6A246',
    textDecoration: 'none'
  }
}))

export interface AuthLayoutProps {
  title: string
  children: ReactNode
}

export const AuthLayout: FC<AuthLayoutProps> = ({ children, title, ...props }) => {
  const { classes } = useStyles()
  return (
    <Container
      className={classes.container}
      p={0}
    >
      <Box className={classes.leftPanel}>
        <Paper
          shadow='md'
          p='md'
          className={classes.overlay}
        >
          <Title
            align='center'
            order={3}
          >
            Welcome to Construction projects management system.
          </Title>
        </Paper>
      </Box>
      <Paper
        p={30}
        radius={8}
        className={classes.rightPanel}
      >
        <Image
          src='/logo.png'
          alt='Logo'
          width={150}
          mb={60}
        />
        <Title
          align='center'
          order={2}
          mb={20}
        >
          {title}
        </Title>
        {children}
      </Paper>
    </Container>
  )
}
